import history from './history';
import { HTTP_STATUS } from '../components/constants';
import { AUTH_URL_VALIDATE } from '../components/constants';
import store from '../redux/store';
import { startLoader, stopLoader } from '../redux/features/loader';
import DasSnackbar from '../components/das-snackbar/DasSnackbar';
import { validateTokenExpiryAndGetToken } from './validate-token';
import { LOGIN } from '../components/constants/route';
import { AUTHENTICATION } from '../components/constants/api-paths';
import { t } from 'i18next';
import { setDrawer } from 'redux/features/drawer';
import { ACCESS_GROUP_NAMES } from 'components/constants/access-group-name';
/*eslint no-unused-vars: ["error", { "args": "none" }]*/
const baseUrl = `${process.env.REACT_APP_API_URL}`;
//const baseUrl = `http://localhost:8080/api/v1`;
type methodType = 'GET' | 'POST' | 'PUT' | 'DELETE' | 'PATCH' | 'DOWNLOAD';

export { useFetchWrapper };

function useFetchWrapper() {
  return {
    get: request('GET'),
    post: request('POST'),
    put: request('PUT'),
    delete: request('DELETE'),
    patch: request('PATCH'),
    download: request('DOWNLOAD'),
  };

  // handling api response for all status codes
  async function handleResponse(res: any) {
    let response = await res.then((result: any) => result);

    if (
      [
        HTTP_STATUS.NOT_FOUND,
        HTTP_STATUS.NOT_AUTHORIZED,
        HTTP_STATUS.BAD_REQUEST,
        HTTP_STATUS.CONFLICT,
      ].includes(response.statusCode || response.status)
    ) {
      DasSnackbar.error(t(`API_STATUS_MESSAGE.${response?.messageCode}`));
      return new Error('api failed', response.message);
    }

    if (
      [HTTP_STATUS.OK, HTTP_STATUS.CREATED, HTTP_STATUS.INTERNAL_SERVER, HTTP_STATUS.NOT_ACCEPTABLE].includes(
        response.statusCode || response.status,
      )
    ) {
      return response;
    } else {
      const drawer = store.getState().drawer.drawer;
      // Access Denied ...
      if ([HTTP_STATUS.FORBIDDEN].includes(response.status)) {
        // Guest gets react tour so we need to allow him to explore even if we have 403
        const Groups = store?.getState()?.auth?.profile?.Groups;
        if (Groups?.includes(ACCESS_GROUP_NAMES.GUEST) ||
          Groups?.includes(ACCESS_GROUP_NAMES.GUESTSCHEDULER) ||
          Groups?.includes(ACCESS_GROUP_NAMES.GUESTTHIRDPARTY)
        ) {
          return;
        }
        // if not guest then
        else {
          if (drawer && Array.isArray(drawer) && drawer?.length > 0) {
            const baseUrl = window.location.origin + window.location.pathname;
            window.location.href = baseUrl;
            store.dispatch(setDrawer([]));
          }
          else {
            const baseUrl = window.location.origin + window.location.pathname;
            window.location.href = baseUrl;
          }
        }
      }
      return {
        messageCode: response.messageCode

      };
    }
  }

  function request(method: methodType) {
    return async (url: any, body?: any, header: any = {}): Promise<any> => {
      let auth: { Authorization?: boolean | string } = {};
      // Get the current state of the store
      const state = store?.getState();

      // Access a specific piece of state
      const authentication = state && state?.auth;
      const authData = authentication && authentication?.profile;

      //Auth Header will not be fetch for auth/signIn and auth/refreshToken Apis
      if (!AUTH_URL_VALIDATE(url)) {
        auth = await authHeader(authData);
        // If the Authorization not valid we are redirecting to login
        if (!auth.Authorization) {
          localStorage.removeItem('persist:root');
          history.push(LOGIN);
          return;
        }
      }
      const requestOptions: any = {
        method,
        ...(method === 'DOWNLOAD' && { method: 'GET' }),
        headers: {
          ...auth,
          ...header,
          'x-api-key': process.env.REACT_APP_X_API_KEY,
        },
      };
      if (body) {
        if (header['Content-Type'] === 'application/x-www-form-urlencoded') {
          requestOptions.body = body;
        } else {
          requestOptions.headers['Content-Type'] = 'application/json';
          requestOptions.body = JSON.stringify(body);
        }
      }
      try {
        store.dispatch(startLoader());

        var localURL = `${baseUrl}/${url}`;
        /*if(url.includes("application")) {
          localURL = `http://localhost:8080/api/v1/${url}`;
        }
        if(url.includes("onboarding")) {
          localURL = `http://localhost:8080/api/v1/${url}`;
        }*/

        return fetch(localURL, requestOptions)
          .then(res => {
            store.dispatch(stopLoader());

            if (res.status === HTTP_STATUS.NOT_AUTHORIZED) {
              if (url.includes(AUTHENTICATION.LOGIN_API)) {
                return handleResponse(res.json());
              }
              localStorage.removeItem('persist:root');

              history.push(LOGIN);
            } else {
              if (method === 'DOWNLOAD') {
                return res.blob();
              }

              return handleResponse(res.json());
            }
          })
          .catch(err => {
            store.dispatch(stopLoader());
            console.log(err);
          });
      } catch (e) {
        console.log('e', e);
      }
    };
  }
  async function authHeader(
    authData: any,
  ): Promise<{ Authorization: string | boolean }> {
    // return auth header with jwt if user is logged in and request is to the api url

    return {
      Authorization: await validateTokenExpiryAndGetToken(authData, request),
    };
  }
}
