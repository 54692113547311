import { createSlice } from "@reduxjs/toolkit";
import { endOfDay, format, startOfDay, subDays } from 'date-fns';
import { customSort } from "../../utils/helper";

const initialState: any = {
  merchantData: [],
  legalNamesList: [],
  reasonCodeList: [],
  allReasonCodes: [],
  dasmidOptions: [],
  initialFilter: "",
  unCheckedArray: [],
  subsidiaryList: [],
  deepLink: ""
};
// createSlice
export const configSlice: any = createSlice({
  name: "config",
  initialState,
  reducers: {
    setDasmidOptions: (state, action) => {
      state.dasmidOptions = action.payload;
    },
    setDeepLink: (state, action) => {
      state.deepLink = action.payload;
    },
    setUnCheckedArray: (state, action) => {
      state.unCheckedArray = action.payload;
    },
    setMerchantData: (state, action) => {
      state.merchantData = action.payload;

      const legalNameList = action.payload?.map(({ LegalName }: any) => {
        return LegalName;
      });
      state.legalNamesList = customSort(legalNameList);
    },
    setReasonCodeList: (state, action) => {
      state.reasonCodeList = action.payload;
      state.allReasonCodes = Object.values(action.payload)?.flat(1);
    },
    setSubsidiaryList: (state, action) => {
      state.subsidiaryList = action.payload;
    },
    resetConfigData: (state) => {
      state.merchantData = [];
      state.legalNamesList = [];
      state.reasonCodeList = [];
      state.allReasonCodes = [];
      state.dasmidOptions = [];
      state.initialFilter = "";
      state.deepLink = "";
      state.unCheckedArray = [];
      state.subsidiaryList = [];
    },
    setInitialFilter: (state) => {
      if (state.initialFilter) {
        return;
      } else {
        const startDate = format(startOfDay(subDays(new Date(), 6)), "yyyy/MM/dd HH:mm:ss");
        const endDate = format(endOfDay(new Date()), "yyyy/MM/dd HH:mm:ss");
        state.initialFilter = `&StartDate=${startDate}&EndDate=${endDate}`;
      }
    },
    resetInitialFilter: (state) => {
      state.initialFilter = "";
    },
  }
});
export const reasonCodeList = (state: any) => state?.config?.reasonCodeList;
export const allReasonCodes = (state: any) => state?.config?.allReasonCodes;
export const { setDeepLink, setUnCheckedArray, setDasmidOptions, setMerchantData, setReasonCodeList, resetConfigData, setInitialFilter, resetInitialFilter, setSubsidiaryList } = configSlice.actions;
export default configSlice.reducer;
